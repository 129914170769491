import React, { useContext, useEffect, useState } from 'react'
import Wrapper from '../components/Wrapper/Wrapper';
import DataContext from '../utils/DataContext';
import { format, parseISO } from "date-fns";
import CoinAreaChart from '../components/Chart/CoinAreaChart';
import { useLocation } from 'react-router-dom'
import { getData, GetDataV2, getChart } from '../components/_Fetcher'
import CoinDetailsLoader from '../components/Loader/CoinDetailsLoader';

const currency_format = { maximumFractionDigits: 2, minimumFractionDigits: 2};

const Coin = () => {

    const {coins, setCoins, setPage} = useContext(DataContext)
    let prices = []
    const [chartData, setChart] = useState([])

    let location = useLocation().pathname.slice(6)


    useEffect(() => {
        async function fetch() {
                if(!coins.data && !coins.coin){
                    try {
                        const response = await getData(500,1)
                        const data =  Object.values(response)
                        let filteredCoin = data.filter(symbol => symbol.slug === location)
                        console.log('FRESH DATA FETCHED:', filteredCoin[0].name);
                        let chart_result = await getChart([filteredCoin[0].chart,'?days=7&interval=hourly'].join(''))
                        // let output = await chart_result
                        setChart(prev => chart_result)
                        return setCoins({...coins, data, coin: filteredCoin[0]})
                        
                    } catch (error) {
                        console.log(error.name,error.message);
                        return <div className="content">Something went wrong {location} | {error.message}</div>
                    }
                }

                let filteredCoin = coins.data.filter(symbol => symbol.slug === location)
                console.log('CACHED DATA FETCHED:', filteredCoin[0].name);
                let chart_result = await getChart([filteredCoin[0].chart,'?days=7&interval=hourly'].join(''))
                let output = await chart_result
                setChart(prev => output)

                return coins

        }
        fetch()
    }, []);

    if (!coins.coin && !prices.length ) return <CoinDetailsLoader location={location} />

    const {  
        image_url:{ medium },
        image, 
        name,
        sector, 
        slug, 
        symbol, 
        profile : {
            tagline,
            overview,
            market_data,
            token_details,
        }
    } = coins.coin



    setPage(slug.toUpperCase())
    document.title = `${name} (${symbol}) | Entrepreneurs's Portfolio`
    



    const color = () => {
        if (chartData.length) {
            let start = chartData[0].value;
            let end = chartData[chartData.length - 1].value;
            if (end > start) {return "#0ff8e1"};
            return '#71DBB7';
        }
        return console.log("Color: loading data...");
    };


    chartData.map((price) => {
        return prices.push({
            value: price[1],
            date: price[0] && new Date(price[0]).toISOString().substr(0,10),
        })
    })


    // console.log('sssssssss',chartData[1]);


    return (
        <Wrapper>
           <div className="content">
               <div className="coin-header">
                   <div className="left">
                    <p className='tagline'>{tagline}</p>
                    <p className='price'>₱ {market_data.price_php.toLocaleString("en-US", currency_format)}</p>
                   </div>
                    <img style={{ height: '64px'}} src={image? image.small : medium} alt={slug} />
               </div>
                <div className="chart">
                <CoinAreaChart data={prices} color={color()} content={CustomTooltip}/>
                </div>

                <div className="token-details">
                    {Object.entries(token_details).map((token_detail, index) => {
                        let title = token_detail[0].replace('_', ' ').replace('_', ' ').replace('_', ' ')
                        return(
                        <div className="row" key={index}> 
                            <h4>{title.toUpperCase()}</h4>
                            <p>{ token_detail[1] || '- - -'}</p>
                        </div>
                    )})}
                </div>
                <h3>About <span>{name}</span></h3>
                <p dangerouslySetInnerHTML={{
                    __html : overview
                }} />
                <h3>Sector</h3>
                <p>{sector}</p>


                <h3> HELLOW</h3>
           </div>
        </Wrapper>
    )
}


function CustomTooltip({ active, payload, label }) {
    try {
      if (active) {
        const [{ value }] = payload;
        return (
          <div className="tooltip">
            <h4>{format(parseISO(label), "eeee, d MMM, yyyy")}</h4>
            <p>PHP {value.toLocaleString("en-US", currency_format)}</p>
          </div>
        );
      }
      return null;
    } catch (error) {
      return console.log("Custom Tooltip");
    }
}



export default Coin

import React, { useContext } from 'react'

import { motion } from 'framer-motion';
import TrendsLoader from '../Loader/TrendsLoader'
import DataContext from '../../utils/DataContext'
const CoinTrends = () => {
    const { coins : { top } } = useContext(DataContext)
    if (!top) return <TrendsLoader/>
    
    return (
        <div>
            <h2>Trends</h2>
            <div className="trends-table-wrapper">

            <motion.div className="trends-table"
                                      
            >
            {top.map(coin => (
                <React.Fragment key={coin._id}>
                  <motion.div className="trends-row"
                    whileTap={{ scale: 0.95 }}
                    whileFocus={{ scale: 0.7}}
                  >
                    <div className="trends-header">
                        <img  src={coin.image? coin.image.small : coin.image_url.medium} alt={coin.slug} />
                        <h3 className={
                          coin.profile.market_data.percent_change_24_hour > 0
                          ? 'percent-up'
                          : 'percent-down'}>
                          { coin.profile.market_data.percent_change_24_hour 
                          && coin.profile.market_data.percent_change_24_hour.toFixed(2)}%</h3>

                    </div>
                    <div className='trends-content'>
                      <div className='trends-footer'>
                      <h3 className='trends-title' >{coin.name} </h3>
                        <p>₱ {coin.profile.market_data.price_php.toLocaleString()}</p>
                      </div>
                    </div>
                  </motion.div>
              </React.Fragment>
            ))}
            </motion.div>
            </div>
        </div>
    )
}

export default CoinTrends

import React from 'react'

const Wrapper = ({children, ...props}) => {
    return (
        <section className="wrapper">
            <div className="container">
                {children}
            </div>
        </section>
    )
}

export default Wrapper

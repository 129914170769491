import React, { useContext, memo } from 'react'
import Wrapper from '../Wrapper/Wrapper'
import DataContext from '../../utils/DataContext';
import {search} from '../Modals/MenuIcons'

const isEqual = () => {
     return false
}
const Navbar = ( { page, setToggle }) => {
    // const { page } = useContext(DataContext)

    console.log('♻  navbar is rendering');
    return (
            <header className="navbar">
                <Wrapper>
                    <div className="nav-content">
                        <h3>{page}</h3>
                        <div className="nav-menu-button">
                            <button>{search}</button>|
                            <button onClick={setToggle}> • • • </button>
                        </div>
                    </div>
                </Wrapper>
            </header>
    )
}

export default memo(Navbar)

import React from 'react'
import Wrapper from '../Wrapper/Wrapper'

const ErrorHandler = ({error}) => {
    return (
        <Wrapper>
            <div className="content">

                <h2>{error.name}</h2>
                <p>{error}</p>
            </div>
        </Wrapper>
    )
}

export default ErrorHandler

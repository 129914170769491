import React from 'react'

const CryptoListLoader = () => {
    return (
        <div className='coin-table'>
            <Row />
            <Row />
            <Row />
            <Row />
            <Row />
            <Row />


        </div>
    )
}


function Row() {
        return (
            <div className="coin-row">
                <div className="coin-icon-loader"/>
                <div className="coin-name-loader"/>
                <div className="coin-price-loader"/>
            </div>
        )
}

export default CryptoListLoader

import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then(res => res.json())

export async function getChart(chart) {
  let result = await fetch(chart)
  let response = await result.json()
  return response
}

export const getData = async (limit,page) => {

    try {
      const response = await fetch(`/api/coins/v2?page=${page}&limit=${limit}`)
      const body = await response.json()
      if (response.status !== 200) {
        throw Error(body.message)
      }
       console.log('fetched coins data success!');
       return body.result
      
    } catch (error) {
      console.log('error', error);
    }
}



export const GetDataV2 = () => {
  let url = '/api/coins'
  const { data, error} = useSWR(url, fetcher)
  return {data, error}
}



export const getTrends = async () => {
  try {
    const response = await fetch(`/api/coins/trending`)
    const body = await response.json()
    if (response.status !== 200) {
      throw Error(body.message)
    }
     console.log('fetched trends data from database');
     return Object.values(body.result)
    
  } catch (error) {
    console.log('error', error);
  }
}


export const getNews = async (query,setLoader) => {
  try {
    setLoader(true)
    const response = await fetch(`/api/news?q=${query}`)
    const body = await response.json()
    if (response.status !== 200) {
      throw Error(body.message)
    }
     console.log('get coins data success!');
     setLoader(false)
     return body.articles
    
  } catch (error) {
    console.log('error', error);
  }
}
import Wrapper from '../Wrapper/Wrapper';
import CoinTable from './CoinTable';
import CoinTrends from './CoinTrends';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useState, memo } from 'react';
import CryptoListLoader from '../Loader/CryptoListLoader';
import DataContext from '../../utils/DataContext';


const Coins = props => {
  const {tab, setTab, setCoins, coins, token} = useContext(DataContext)
  const [onLoad, setLoad] = useState(false)
  
  const [ pagination, setPagination] = useState({ limit: 10, page: 1})

  // const categoriesV2 = coins && [...new Set(coins.DataContext.map(category => category.sector))]
  console.log('♻  coins list is rendering', props.keys);

  let categories = [
    { sector: 'Currencies', name: 'Currencies',limit: '', page: 1},
    { sector: 'Gaming', name: 'Gaming',limit: '', page: 1},
    { sector: 'Decentralized Exchanges', name: 'DEFI',limit: '', page: 1},
    { sector: 'Smart Contract Platforms', name: 'Smart Contract Platforms',limit: '', page: 1},
    { sector: 'Stablecoins', name: 'Stable Coins',limit: '', page: 1},
  ]
  
  // console.log(GetDataV2().data)
  
    const toggleTabs = async (tab,sector, limit, page) => {
      // console.log(sector);

      const filteredCoins = sector
      ? coins.data.filter(coin => {
      return coin.sector && coin.sector[0] === sector}) 
      : coins.data

      setLoad(true)
      setTab({tab,sector})
      setPagination({limit,page})
      
      setCoins({...coins, filteredCoins})
      setLoad(false)
    }


    return (
      <Wrapper>
        <AnimatePresence>

        
      <motion.div className="content"
            key={token}
            initial={{ y : '100vh', opacity: 0}}
            animate={{ y: 0, opacity: 1}}
            exit={{y: '100vh', opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3}}
      >
        <CoinTrends />
          <h2>Markets</h2>
          <div className="market-header">
          <div className="categories-wrapper">
            <div className="categories">
            <button
              className={tab.tab === 0 ? 'active' : null }
              onClick={() => toggleTabs(0, '', 10)}
              >Top Assets
            </button>

            {categories.map((category, index) => (
              <button key={index} 
               className={tab.tab === (index + 1)? 'active' : null }
                onClick={() => toggleTabs((index + 1), category.sector, category.limit, category.page)}
              >{category.name}</button>
            ))}
            </div>

            <button
              className={tab.tab === 8 ? 'active' : null }
              onClick={() => toggleTabs(8, '', 100)}
              >See all</button>
          </div>
        </div>
       {coins? <CoinTable sector={tab.sector} limit={pagination.limit} page={pagination.page} onload={onLoad} setOnload={setLoad} /> :  <CryptoListLoader /> }  
       {/* <CoinTable 
        sector={tab.sector} 
        limit={pagination.limit} 
        page={pagination.page} 
        onload={onLoad} 
        setOnload={setLoad}/> */}
      </motion.div>
      </AnimatePresence>
      </Wrapper>
    );
}



export default memo(Coins)

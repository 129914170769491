import React from 'react'

const TrendsLoader = () => {
    return (
        <div>
            <h2>Trends</h2>
        <div className="trends-table-wrapper">
            <div className="trends-table">
                <div className="trends-row-loader">
                    <div className="trends-icon-loader"/>
                    <div className="trends-footer-loader"/>
                </div>
                <div className="trends-row-loader">
                    <div className="trends-icon-loader"/>
                    <div className="trends-footer-loader"/>
                </div>
                <div className="trends-row-loader">
                    <div className="trends-icon-loader"/>
                    <div className="trends-footer-loader"/>
                </div>
            </div>
        </div>
        </div>
    )
}

export default TrendsLoader

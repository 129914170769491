import React, { useState, useEffect, useContext } from 'react';
import { getData, GetDataV2, getTrends } from '../_Fetcher'
import DataContext from '../../utils/DataContext';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import CryptoListLoader from '../Loader/CryptoListLoader';
import ErrorHandler from '../Loader/ErrorHandler';


const CoinTable = ({ sector, limit, page , onload, setOnload }) => {

    const { coins, setCoins, tab} = useContext(DataContext)

    useEffect(() => {
      setOnload(true)
      async function mainFetch() {
        if(!coins.data && !coins.top) {
          try {
            const response = await getData(500,1)
            const top = await getTrends()
            if(response) {
                const data = Object.values(response)
                console.log('FRESH DATA FETCHED (MAIN)')
                return  setCoins({...coins, data, list: data, top})
            }
          } catch (error) {
            console.log(error.name, error);
            return <ErrorHandler error={error}/>
          }
        }
        console.log('CACHED DATA FETCHED (MAIN)');
        return
      }
      setOnload(false)


      mainFetch()
    },[coins.top, coins.data])
    
    if (!coins.data) return <CryptoListLoader/>
    
    let list = tab.tab === 0? coins.data.slice(0,20) : coins.filteredCoins

    return (
        <div className='coin-table'
        >
            {list.map((coin, index) => {
              // let current = coin.slug
              return(
              <motion.div key={coin._id}
              initial={{ y : 20, opacity: 0}}
              animate={{ y: 0, opacity: 1}}
              
              transition={{ delay: index/10}}
              >
                <Link  to={`/coin/${coin.slug}`}
                onClick={() => setCoins({
                  ...coins, coin})
              }
                >
                  <div 
                    className="coin-row"
                    
                  >
                    <div className="coin-row-detail">
                        <img className='coin-row-image' src={coin.image_url.small} alt={coin.slug} />
                        <div className="coin-row-name">
                            <h3>{coin.name} </h3>
                            <p>{coin.symbol}</p>
                        </div>
                    </div>
                    <div className="coin-row-price">
                        <h3>₱ {coin.profile.market_data.price_php.toLocaleString()}</h3>
                        <p className={coin.profile.market_data.percent_change_24_hour > 0? 'percent-up': 'percent-down'}>{coin.profile.market_data.percent_change_24_hour && coin.profile.market_data.percent_change_24_hour.toFixed(2)} %</p>
                    </div>
                  </div>
                </Link>
              </motion.div>
            )})}
        </div>
    );
}



  export default CoinTable
  

import React from 'react'
import Wrapper from '../Wrapper/Wrapper'

const CoinDetailsLoader = ({ location, ...props}) => {
    return (
        <Wrapper>
            <div className="content detail-loader">
                
                <div className="detail-loader-header coin-header">
                    <div className="detail-loader-price" />
                    <div className="detail-loader-icon" />
                </div>
                <div className="detail-loader-about-content coin-chart" > 
                    <p>
                        Loading {location} . . .
                    </p>   
                </div>

                <div className="detail-loader-about-title" />
                <div className="detail-loader-about-content-large" />
                <div className="detail-loader-about-content-short" />


            </div>
        </Wrapper>
    )
}

export default CoinDetailsLoader

import React, { useState } from 'react'

export const Theme = (props) => {
    const [ isDark, setDark ] = useState(true);
    
    /**
     * This Section is to add the theme data to parent doc of html tag
     * this also be added to root of this application for theme checker or theme data persistence 
     */
    if (localStorage.theme === 'light' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: light)').matches)) {
       
        document.documentElement.setAttribute('data-theme', 'light')
        document.head.childNodes[7].content = `#CDD7EB`
    } else { 
        document.documentElement.setAttribute('data-theme', 'dark')
        document.head.childNodes[7].content = `#353A4F`
    }

    /*********************      End of Section  *********************/



    function toggleTheme() {
        navigator.vibrate(50)
        isDark? localStorage.theme = 'light' : localStorage.theme = 'dark' 
        setDark(!isDark)
    }
    return (
        <button onClick={toggleTheme} {...props}>
           {props.icon} <p>{`Toggle ${localStorage.theme === 'light'? 'dark' : 'light'} Mode`}</p>
        </button>
    )
}
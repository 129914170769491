import React, {memo} from 'react'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import {home,coins,news,sun,info} from './MenuIcons'
import { Theme } from '../../utils/Theme.utils';


const MenuModal = ({ toggle, setToggle }) => {
    // if(!toggle) return null
    return (
        <AnimatePresence initial={false}>

        {toggle && <motion.div className="wrapper modal"
            
        >
            <motion.div className="backdrop" onClick={() => setToggle(false)}/>
            <motion.div className="modal-content"
                        key="modal"
                        initial={{ y : '100%', opacity: 0}}
                        animate={{ y: 0, opacity: 1}}
                        exit={{y: '100%', opacity: 1 }}
                        transition={{  type: "spring", stiffness: 100}}
                >

            <Route setToggle={setToggle} />
            <div className='separator-modal' />
            <div className="footer">
                <p>© Adazolhub.com</p>
                <p> •  Policy</p>
                <p> •  Terms and Conditions</p>
            </div>
            </motion.div>
        </motion.div>}
                </AnimatePresence>

    )
}

function Route ({ setToggle }) {
    let links = [
        { 
            icon: home,
            name: 'Home Page',
            link: '',
            disable: false
        },
        { 
            icon: coins,
            name: 'Coins List Panel',
            link: 'coins',
            disable: false
        },
        { 
            icon: news,
            name:'News and Update Panel',
            link: 'news',
            disable: false
        }]


    return (
        <>
        {links.map((link,index) =>

        <Link to={`/${link.link}`} key={index} onClick={setToggle}>
            <button disabled={link.disable}>
                 {link.icon} <p>{link.name}</p>
            </button>
        </Link>

        )}
        <Theme icon={sun} />
        <Link to={`/about`} onClick={setToggle}>
            <button >
                 {info} <p>About</p>
            </button>
        </Link>
        </>
    )
}

export default memo(MenuModal)

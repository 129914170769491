import React, { useState, useCallback } from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import {Router as Routes} from './router/route';

import Navbar from './components/Header/Navbar';
import Coins from './components/Coins/Coins';

import DataContext from './utils/DataContext'
import PageNavigationListenerUtils from './utils/PageNavigationListener.utils';
import MenuModal from './components/Modals/MenuModal';

function App() {

  if ( (!('theme' in localStorage) ) || localStorage.theme === 'dark' ) {
   
    document.documentElement.setAttribute('data-theme', 'dark')
    document.head.childNodes[7].content = `#353A4F`
  } else { 
    document.documentElement.setAttribute('data-theme', 'light')
    document.head.childNodes[7].content = `#CDD7EB`
  }

  let data = Data()
  return (
    <DataContext.Provider value={data}>
    <Router>
      <PageNavigationListenerUtils/>    
      <Navbar page={data.page}  setToggle={data.modalToggler} />
      <Routes />
      <MenuModal toggle={data.menuToggle} setToggle={data.modalToggler} />
    </Router>
    </DataContext.Provider>

  );
}


function Data() {
  const [menuToggle, setMenuToggle] = useState(false)
  const modalToggler = useCallback(() => setMenuToggle(!menuToggle), [menuToggle])
  const [ token, setToken ] = useState('')


  const [coins, setCoins] = useState({
    current: null, 
    data: null, 
    coin: null, 
    top: null, 
    filteredCoins: [], 
    list: null})
  const [page, setPage] = useState('Crypto')

  const [ tab, setTab ] = useState({tab: 0, sector: ''})

  let context = {
    menuToggle, setMenuToggle,
    coins, setCoins,
    page, setPage,
    tab, setTab,
    token, setToken,
    modalToggler

  }

  return context
}






export default App;

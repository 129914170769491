import React, { useContext } from 'react'
import Coins from '../components/Coins/Coins'
import Navbar from '../components/Header/Navbar'
import DataContext from '../utils/DataContext';

const Crypto = props => {
    const { page, setPage } = useContext(DataContext)
    setPage('Crypto')
    document.title = `${page} | Entrepreneur's Portfolio`
    return (
        <>
            {/* <Navbar/> */}
            <Coins keys={props.keys}/>
        </>
    )
}

export default Crypto

import { lazy, Suspense, useContext } from 'react';
import {Route, Switch, useLocation} from 'react-router-dom'
import Coin from '../pages/Coin';
import { AnimatePresence } from 'framer-motion'
// import { TestPage } from '../pages/TestPage';
import Crypto from '../pages/Crypto';
import DataContext from '../utils/DataContext';
const Home = lazy(() => import('../pages/Home'))
const News = lazy(() => import('../pages/News'))
const About = lazy(() => import('../pages/About'))
const PageNotFound = lazy(() => import('../pages/PageNotFound'))




export const Router = () => {
  const { setToken } = useContext(DataContext)

  const location = useLocation()

  setToken(location.key)


  // console.log({key:location.key, path: location.pathname});
  
  const loading = <h2 className="coin-loader">Loading ...</h2>
  
  return (
      <Suspense fallback={loading}>
          <Switch location={location} >
            <Route path='/home' exact >
              <Crypto />
            </Route>
            <Route path='/coins' exact > 
              <Home keys={location.key} />
            </Route>
            <Route path='/coin/:slug' exact component={Coin} />
            <Route path='/news' exact component={News} />
            <Route path='/about' exact component={About} />
            <Route path='/' exact > 
              <Crypto keys={location.key} />
            </Route>
            <Route component={PageNotFound} />
          </Switch>
      </Suspense>
  );
}